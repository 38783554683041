@import "../../config/constant.scss";

$size: 'bs';

@mixin btn($size) {
    border-radius: 2px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 500;
    line-height: 17px; 
    align-items: center;
    cursor: pointer;
    @if $size == 'sm' {
        width: 110px;
        height: 40px;
    } @else if $size == 'smd' {
        width: 200px;
        height: 40px;
    } @else if $size == 'md' {
        width: 200px;
        height: 50px;
    } @else if $size == 'lg' {
        width: 300px;
        height: 50px;
    } @else if $size == 'lg-fs-md' {
        width: 300px;
        height: 50px;
        font-size: 12px;
    } @else {
        width: 100%;
        height: 50px;
    }
}

@mixin btn-rb($bg-color, $bd-color, $font-color) {
    background-color: $bg-color;
    border: 1px solid $bd-color;
    color: $font-color;    
    text-transform: uppercase;
    &:hover { color: $font-color; }
    &:disabled {
        background-color: desaturate($bg-color, 30%);
        cursor: default;
    }
}

.btn-rb-blue{
    @include btn-rb($rb-blue, $rb-blue-border, $rb-white)
}

.btn-rb-green{
    @include btn-rb($rb-light-green, $rb-light-green, $rb-white)
}

.btn-rb-white{
    @include btn-rb($rb-white, $rb-blue-border, $rb-blue)
}

.btn-rb-grey{
    @include btn-rb($rb-white, $rb-light-grey, $rb-dark-grey)
}

.btn-rb-yellow{
    @include btn-rb($rb-yellow, $rb-yellow, $rb-white)
}

.btn-bs{
    @include btn('bs')
}

.btn-sm{
    @include btn('sm')
}

.btn-smd{
    @include btn('smd')
}

.btn-md{
    @include btn('md')
}

.btn-lg{
    @include btn('lg')
}
.btn-lg-fs-md{
    @include btn('lg-fs-md')
}