@import '../../config/constant.scss';

.login-form {
    width: 100%;
    max-width: 400px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 100px;
    margin: auto;
}

.login-title {
    color: $rb-dark-blue-opaque;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
}

.login-text {
    color: $rb-dark-blue-opaque;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}

.login-forgot-password {
    color: $rb-basic-blue;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
}
