@import '../../config/constant.scss';


.merchant-detail-label {
    color: $rb-dark-blue-opaque;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
}

.merchant-row-fixed {
    background-color:$rb-white;
    position: fixed;
    top: 90px;
    left: 50px;
    z-index: 3;
    width: 94.8%;
}
