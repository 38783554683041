@import '../../config/constant.scss';

.link-file {
    color:$rb-dark-blue-opaque;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-decoration: underline;
  }