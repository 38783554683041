// Constant colors
$rb-light-aqua: #F5F9FC;
$rb-lighter-aqua: #A6B7C4;
$rb-aqua: #EEF3F6;                 
$rb-dark-aqua: #DCE8F2;
$rb-dark-blue-opaque: #143357;
$rb-dark-blue: #23408F;
$rb-basic-blue:#006DBA;
$rb-blue: #0071CE;
$rb-white: #FFFFFF;
$rb-black: #202020;
$rb-grey: #8D8D90;
$rb-lighter-grey: #EAEAEA;
$rb-light-grey: #D7D8D9;
$rb-dark-grey: #909090;
$rb-darker-grey: #7F8083;
$rb-yellow: #FA9016;
$rb-dark-yellow: #F18C18;
$rb-purple: #766ED7; 
$rb-light-shadow: #0000001A; 
$rb-blue-border: #006DBA;
$rb-green: #00C283;
$rb-light-green: #6ED79A;
$red: #DC3545;
