@import "node_modules/bootstrap/scss/bootstrap";
@import "../config/constant.scss";
@import "../master-components/Spinner/Spinner.scss";

body, html {
  background-color:$rb-light-aqua;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  margin: 0; 
  padding: 0;
}



.clickable {
  cursor: pointer;
}

.grid-row-header {
  height: 50px;
}

.grid-top-border {
  border-top: 1px solid $rb-dark-aqua;
}

.grid-bottom-border {
  border-bottom: 1px solid $rb-dark-aqua;
}

.grid-row-detail {
  height: 60px
}

.grid-text-header {
   color: $rb-lighter-aqua;
   font-size: 14px;
   font-weight: 400;
   text-align: left;
}

.grid-text-detail {
  color: $rb-dark-blue-opaque;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
