@import '../../config/constant.scss';

.spinner {
    width: 150px;
    height: 150px;
    margin: 100px auto 0;
    text-align: center;
    position: relative;
    top: 50%;
    margin-top: -16px;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: $rb-blue;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
    animation: bouncedelay 1.4s infinite ease-in-out;
    /* Prevent first frame from flickering when animation starts */
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
  
.mask {
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1200;
    &.hide {
        display: none;
    }
}

@-webkit-keyframes bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0.0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes bouncedelay {
    0%, 80%, 100% { 
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 40% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

