
@import '../../config/constant.scss';

.dashboard-header-container {
    background-color: $rb-aqua;
    width: 100%;
    margin: 0 auto;
    height: 70px;
}

.dashboard-header-text {
    color:$rb-dark-blue-opaque;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.dashboard-header-logout {
    color:$rb-dark-blue-opaque;
    font-family: Roboto;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 14px;
}

.dashboard-body-container {
    background:$rb-white;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    border: 1px solid $rb-dark-aqua;
    width: 95%;
    margin: 0 auto; 
}

.dashboard-header-fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
}

.dashboard-subeader-space {
    background-color:$rb-light-aqua;
    position: fixed;
    top: 70px;
    left: 35px;
    z-index: 2;
    width: 100%;
}



