
@import "../../config/constant.scss";

.rb-select {
    border-color:  $rb-dark-aqua !important;
    font-size: 14px!important;
    color: $rb-black!important;
    &.is-invalid {
      .rb-select__control {
        border-color: $red!important;
      }
    }
    &__control{
      font-size: 14px!important;
      height: 40px;
      border-radius: 2px!important;
      border-color:  $rb-dark-aqua !important;
      &--is-focused {
        box-shadow: none!important;
        border-color:  $rb-dark-aqua !important;
      }
      .rb-select__dropdown-indicator {
        padding: 0!important;
        margin-right: 20px;
        border-top: 0.3em solid $rb-black;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        &:hover {
          border-top: 0.3em solid $rb-black;
        }
        svg {
          display: none;
        }
      }
      &--menu-is-open {
        box-shadow: none!important;
        border-color:  $rb-dark-aqua!important;
        .rb-select__dropdown-indicator {
          padding: 0!important;
          margin-right: 20px;
          border-bottom: 0.3em solid $rb-black;
          border-right: 0.3em solid transparent;
          border-top: 0;
          border-left: 0.3em solid transparent;
          &:hover {
            border-top: 0.3em solid $rb-black;
          }
          svg {
            display: none;
          }
        }
      }
    }
    &__menu {
      font-size: 14px!important;
      margin: 0!important;
      border-radius: 0 0 2px 2px!important;
      border: 1px solid  $rb-dark-aqua!important;
      border-top: none!important;
      box-shadow: none!important;
      transform: translateY(-1px);
    }
    &__indicator-separator {
      display: none;
    }
    &__option {
      &--is-selected, &--is-focused {
        color: $rb-black!important;
        background-color: #f8f9fa!important;
      }
    }
  }
  
  .rb-select.isMulti {
    .rb-select__control {
      height: initial;
    }
  }